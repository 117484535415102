import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Map } from "./Map";
import { CurrentPlaceName } from "./CurrentPlaceName";

import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";

var polyUtil = require("polyline-encoded");

// Firebase config
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDfKM3_Ben9mDGvkqymYk7HXw1KPDicsrY",
  authDomain: "strava-dashboard-17504.firebaseapp.com",
  databaseURL: "https://strava-dashboard-17504-activities.firebaseio.com",
  projectId: "strava-dashboard-17504",
  storageBucket: "strava-dashboard-17504.appspot.com",
  messagingSenderId: "355220280763",
  appId: "1:355220280763:web:3141fc5dc37da7c44a609e",
  measurementId: "G-NKQ6EZ0XKZ",
};

const GEOAPIFY_API_KEY = "6bb082a878444fc6bd9af2b2f869c17c";

const START_DATE_THRESHOLD = "2022-06-07";
const END_DATE_THRESHOLD = "2022-06-30";

// Functions to filter activities
const lejogActivity = (elem) => {
  const start_date_string = elem["start_date"];
  const activity_date = Date.parse(start_date_string);
  const start_date_threshold = Date.parse(new Date(START_DATE_THRESHOLD));
  const end_date_threshold = Date.parse(new Date(END_DATE_THRESHOLD));
  return (
    activity_date > start_date_threshold && activity_date < end_date_threshold
  );
};

function App() {
  const [filteredActivityData, setFilteredActivityData] = useState([]);
  const [currentLocality, setCurrentLocality] = useState("");
  const [currentRegion, setCurrentRegion] = useState("");
  const [currentTotalKm, setCurrentTotalKm] = useState(0);
  const [currentTotalAscent, setCurrentTotalAscent] = useState(0);

  // Initialize Firebase
  initializeApp(FIREBASE_CONFIG);
  const db = getDatabase();
  const stravaActivitiesRef = ref(db, "Latest Strava Activities/");

  useEffect(() => {
    onValue(stravaActivitiesRef, (snapshot) => {
      // Get Activities from firebase and process them
      const activityData = snapshot.val();
      console.log(activityData);
      setFilteredActivityData(activityData.filter(lejogActivity));
      console.log(filteredActivityData);
    });
  }, []);

  useEffect(() => {
    // Get Current Place Name using reverse Geocoding
    if (filteredActivityData.length > 0) {
      console.log("Get Current Place Name");
      const polylineEncoded =
        filteredActivityData[0]["map"]["summary_polyline"];
      if (polylineEncoded !== undefined) {
        const polylineDecoded = polyUtil.decode(polylineEncoded);
        const currentLocation = polylineDecoded[polylineDecoded.length - 1];
        const params = {
          lat: currentLocation[0].toString(),
          lon: currentLocation[1].toString(),
          apiKey: GEOAPIFY_API_KEY,
        };
        axios
          .get("https://api.geoapify.com/v1/geocode/reverse?", { params })
          .then((response) => {
            // console.log(response);
            // console.log(response.data.features[0]["properties"]["city"]);
            // console.log(response.data.features[0]["properties"]["county"]);
            setCurrentLocality(response.data.features[0]["properties"]["city"]);
            setCurrentRegion(response.data.features[0]["properties"]["county"]);
            console.log(currentLocality);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // Get Total Distance
    if (filteredActivityData.length > 0) {
      console.log("Get Total Distance");
      const totalKmCalculation =
        Math.round(
          (filteredActivityData
            .map((item) => item.distance)
            .reduce((prev, curr) => prev + curr, 0) /
            1000) *
            10
        ) / 10;
      setCurrentTotalKm(totalKmCalculation);
      console.log(currentTotalKm);
    }

    // Get Total Ascent
    if (filteredActivityData.length > 0) {
      console.log("Get Total Ascent");
      const totalAscentCalculation = Math.round(
        filteredActivityData
          .map((item) => item.total_elevation_gain)
          .reduce((prev, curr) => prev + curr, 0)
      );
      setCurrentTotalAscent(totalAscentCalculation);
      console.log(currentTotalAscent);
    }
  }, [filteredActivityData]);

  return (
    <div id="siteContainer">
      <div id="siteHeader">
        <h2>Rowan's most recent LEJOG location:</h2>
        <CurrentPlaceName
          locality={currentLocality}
          region={currentRegion}
          totalKm={currentTotalKm}
          totalAscent={currentTotalAscent}
        />
      </div>
      <div id="siteContent">
        <div id="mapContainer">
          <Map activities={filteredActivityData} />
        </div>
      </div>
      <div id="siteFooter"></div>
    </div>
  );
}

export default App;
