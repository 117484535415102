import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";

import starImg from "./star-48.png";

var polyUtil = require("polyline-encoded");

mapboxgl.accessToken =
  "pk.eyJ1Ijoicm93YW5zdWdkZW4iLCJhIjoiY2s1emNkaWNzMDhjbDNub2JxcGY2MTRmbyJ9.0H5Lis6IIyV7Dgh8lysqiQ";

const Map = ({ activities }) => {
  //   console.log("Map");

  const mapContainerRef = useRef(null);

  const [lng, setLng] = useState(-4);
  const [lat, setLat] = useState(55);
  const [zoom, setZoom] = useState(4.3);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // style: "mapbox://styles/mapbox/streets-v11",
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [lng, lat],
      zoom: zoom,
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Polylines and Markers
    map.on("load", () => {
      var arrayLength = activities.length;
      console.log("Map load");
      console.log(arrayLength);

      for (var i = 0; i < arrayLength; i++) {
        const polylineEncoded = activities[i]["map"]["summary_polyline"];
        if (polylineEncoded.length !== 0) {
          // Polylines
          const polylineDecoded = polyUtil.decode(polylineEncoded);
          polylineDecoded.forEach((arr) => arr.reverse());
          const routeName = "route" + i;
          map.addSource(routeName, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: polylineDecoded,
              },
            },
          });
          map.addLayer({
            id: routeName,
            type: "line",
            source: routeName,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#FF0",
              "line-width": 2,
            },
          });

          // Markers
          const el = document.createElement("div");
          const width = 10;
          const height = 10;
          el.className = "marker";
          el.style.backgroundColor = "yellow";
          el.style.width = `${width}px`;
          el.style.height = `${height}px`;
          el.style.backgroundSize = "100%";
          new mapboxgl.Marker(el).setLngLat(polylineDecoded[0]).addTo(map);
          const el2 = document.createElement("div");
          el2.className = "marker";
          el2.style.backgroundColor = "yellow";
          el2.style.width = `${width}px`;
          el2.style.height = `${height}px`;
          el2.style.backgroundSize = "100%";
          new mapboxgl.Marker(el2)
            .setLngLat(polylineDecoded[polylineDecoded.length - 1])
            .addTo(map);
        }
      }

      // Current Location Marker
      if (activities.length > 0) {
        const polylineEncoded = activities[0]["map"]["summary_polyline"];
        if (polylineEncoded !== undefined) {
          const polylineDecoded = polyUtil.decode(polylineEncoded);
          polylineDecoded.forEach((arr) => arr.reverse());
          const currentLocEl = document.createElement("div");
          const width = 24;
          const height = 24;
          currentLocEl.className = "marker";
          currentLocEl.style.backgroundImage = `url(${starImg})`;
          currentLocEl.style.width = `${width}px`;
          currentLocEl.style.height = `${height}px`;
          currentLocEl.style.backgroundSize = "100%";
          new mapboxgl.Marker(currentLocEl)
            .setLngLat(polylineDecoded[polylineDecoded.length - 1])
            .addTo(map);
        }
      }

      if (activities.length > 0) {
        const polylineEncoded = activities[0]["map"]["summary_polyline"];
        const polylineDecoded = polyUtil.decode(polylineEncoded);
        const flyTarget = polylineDecoded[polylineDecoded.length - 1];
        const flyTargetSwapped = [flyTarget[1], flyTarget[0]];
        setTimeout(() => {
          map.flyTo({
            center: flyTargetSwapped,
            zoom: 12,
            bearing: 0,
            speed: 1, // make the flying slow
            curve: 1, // change the speed at which it zooms out
            easing: (t) => t,
            essential: true,
          });
        }, 1000);
      }
    });

    map.on("move", () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    // Clean up on unmount
    return () => map.remove();
  }, [activities]);

  return <div className="map-container" ref={mapContainerRef} />;
};

export { Map };
